import React, { useState, useContext, useEffect, useCallback } from 'react';
import { firstBy } from 'thenby';
import useDebounce from '../Hooks/useDebounce';
import '../Utils';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
// import { Link } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CakeIcon from '@mui/icons-material/Cake';
import ClearIcon from '@mui/icons-material/Clear';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DeleteIcon from '@mui/icons-material/Delete';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import EmailIcon from '@mui/icons-material/Email';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FemaleIcon from '@mui/icons-material/Female';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import InfoIcon from '@mui/icons-material/Info';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MaleIcon from '@mui/icons-material/Male';
import PaidIcon from '@mui/icons-material/Paid';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SmsIcon from '@mui/icons-material/Sms';
import TransgenderIcon from '@mui/icons-material/Transgender';
import WarningIcon from '@mui/icons-material/Warning';

/**
 * Dynamic dialog components
 */
import Wizard01MyCompany from './Wizard01MyCompany';
import Wizard02MyBranches from './Wizard02MyBranches';
import Description from './Core/Description';
import Highlighted from './Core/Highlighted';
import Customer from './Customer'
import CustomerAddress from './CustomerAddress';
import CustomerIdentityCard from './CustomerIdentityCard';
import CustomerPhone from './CustomerPhone';
import CustomerEmail from './CustomerEmail';
import GdprAgreement from './GdprAgreement';
import ServiceActionsBeforeOpen from './ServiceActionsBeforeOpen';

//#region Default values
const defaultData = {
  idBranch: '',
  idBeneficiary: '',
  beneficiary: null,
  idCustomer: '',
  customer: null,
  idSpecialty: '',
  serviceText: '',
  hideMyCompanyPrices: false,
  idService: '',
  receptions: [],
  options: {
    beneficiaryPanelExpanded: true,
    customerPanelExpanded: true,
    view: 'servicesSelect', // servicesSelect, serviceActionsBeforeOpen, serviceAfterOpen,
  },
};
const defaultDeps = {       // | handleReload | handleBranchSelect | handleBeneficiarySelect | handleCustomerSearch | handleCustomerSelect |
  myCompany: {},            // |       X      |                    |                         |                      |                      |
  beneficiaries: [],        // |       X      |                    |                         |                      |                      |
  beneficiaryContracts: [], // |              |                    |            X            |                      |                      |
  branches: [],             // |       X      |                    |                         |                      |                      |
  branchPrograms: [],       // |       X      |          X         |                         |                      |                      |
  doctors: [],              // |       X      |          X         |                         |                      |                      |
  doctorContracts: [],      // |       X      |          X         |                         |                      |                      |
  doctorPrograms: [],       // |       X      |          X         |                         |                      |                      |
  customers: [],            // |              |                    |                         |           X          |                      |
  receptions: [],           // |              |                    |                         |                      |           X          |
  services: [],             // |       X      |          X         |            X            |                      |                      |
  servicePrices: [],        // |       X      |          X         |            X            |                      |                      |
  schedules: [],            // |       X      |          X         |            X            |                      |           X          |
};
const defaultAjaxSearch = {
  text: '',
  entities: [],
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};
//#endregion

const Dashboard = props => {
  //#region Constants
  //#endregion

  //#region States
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(defaultData);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  const [ajaxSearchBeneficiary, setAjaxSearchBeneficiary] = useState(defaultAjaxSearch);
  const [ajaxSearchCustomer, setAjaxSearchCustomer] = useState(defaultAjaxSearch);
  //#endregion

  //#region Hooks
  const ac = useContext(AppContext);
  const debouncedAjaxSearchBeneficiaryText = useDebounce(ajaxSearchBeneficiary.text, 300);
  const debouncedAjaxSearchCustomerText = useDebounce(ajaxSearchCustomer.text, 300);
  //#endregion

  //#region Handlers
  const handleDataChange = (property = null, index = null) => evt => {
    console.log(evt.target.type, evt.target.name, evt.target.value);
    let mods = {};
    switch (evt.target.name) {}

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      default:
        value = evt.target.value;
    }

    if (property) {
      if (index) {
        setData(prevState => ({
          ...prevState,
          [property]: Object.assign([], prevState[property], {[index]: {
            ...prevState[property][index],
            [evt.target.name]: value,
          }}),
          ...mods,
        }));
      } else {
        setData(prevState => ({
          ...prevState,
          [property]: {
            ...prevState[property],
            [evt.target.name]: value,
          },
        }));
      }
    } else {
      setData(prevState => ({
        ...prevState,
        [evt.target.name]: value,
        ...mods,
      }));
    }
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    console.table(data);

    // if (data.idBranch || data.idBeneficiary || data.idCustomer) {
      handleReload()();
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.idBranch, data.idBeneficiary, data.idCustomer]);

  useEffect(() => {
    if (false
      || deps.branches.length === 0
      || data.idBranch !== defaultData.idBranch
      || data.idBeneficiary !== defaultData.idBeneficiary
    ) return;

    setData(prevState => ({
      ...prevState,
      idBranch: data.idBranch === defaultData.idBranch ? getBranches().find(item => item.isDefault).id : data.idBranch,
      idBeneficiary: data.idBeneficiary === defaultData.idBeneficiary ? getBeneficiaries().find(item => item.isMyCompany).id : data.idBeneficiary,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, deps]);

  useEffect(() => {
    if (!debouncedAjaxSearchBeneficiaryText || debouncedAjaxSearchBeneficiaryText.length < 3) return;

    ac.ajax('getBeneficiariesSilent', {
      text: debouncedAjaxSearchBeneficiaryText,
    }).then(response => {
      if (response.status.ok) {
        setAjaxSearchBeneficiary({
          ...ajaxSearchBeneficiary,
          entities: response.result.data.customers,
        });
        setDeps(prevState => ({
          ...prevState,
          beneficiaries: response.result.data.beneficiaries,
        }));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAjaxSearchBeneficiaryText]);
  useEffect(() => {
    if (data.idCustomer !== defaultData.idCustomer || !debouncedAjaxSearchCustomerText || debouncedAjaxSearchCustomerText.length < 3) return;

    ac.ajax('getCustomersSilent', {
      text: debouncedAjaxSearchCustomerText,
    }).then(response => {
      if (response.status.ok) {
        setAjaxSearchCustomer({
          ...ajaxSearchCustomer,
          entities: response.result.data.customers,
        });
        setDeps(prevState => ({
          ...prevState,
          customers: response.result.data.customers,
        }));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAjaxSearchCustomerText]);
  //#endregion

  //#region Getters
  const getBranches = useCallback(() => {
    return deps.branches.sort(firstBy('branch'));
  }, [deps]);
  const getBeneficiaries = useCallback(() => {
    return deps.beneficiaries.sort(firstBy('beneficiary'));
  }, [deps]);
  const getCustomers = useCallback(() => {
    return deps.customers.sort(firstBy('surname').thenBy('name').thenBy('cnp'));
  }, [deps]);
  const getCustomerAddress = useCallback(() => {
    const address = data.customer.addresses.find(item => item.isDefault) || data.customer.addresses[0];
    if (address) {
      return `${address.city}, ${address.street || '-'} nr. ${address.streetNumber || '-'}, bl. ${address.building || '-'}, sc. ${address.staircase || '-'}, et. ${address.floor || '-'}, ap. ${address.apartment || '-'}, jud. ${address.county}, ${address.country}`;
    } else {
      return null;
    }
  }, [data.customer]);
  const getCustomerIdentityCard = useCallback(() => {
    const identityCard = data.customer.identityCards.find(item => item.isDefault) || data.customer.identityCards[0];
    if (identityCard) {
      return `${identityCard.identityCardType} ${identityCard.serial || '-'} ${identityCard.number || '-'}, expira la ${identityCard.validTo || '-'}`;
    } else {
      return null;
    }
  }, [data.customer]);
  const getCustomerPhone = useCallback(() => {
    const phone = data.customer.phones.find(item => item.isDefault) || data.customer.phones[0];
    if (phone) {
      return phone.phone;
    } else {
      return null;
    }
  }, [data.customer]);
  const getCustomerEmail = useCallback(() => {
    const email = data.customer.emails.find(item => item.isDefault) || data.customer.emails[0];
    if (email) {
      return email.email;
    } else {
      return null;
    }
  }, [data.customer]);
  const getGdprAgreementType = useCallback((idGdprAgreementType = null) => {
    const gdprAgreementType = deps.gdprAgreementTypes.find(item => item.id === (idGdprAgreementType ?? data.gdprAgreement.idGdprAgreementType));
    return gdprAgreementType ? gdprAgreementType : null;
  }, [data.customer]);
  const getSpecialties = useCallback(() => {
    return deps.specialties.filter(specialty => true
      && deps.services.filter(service => true
        && service.idSpecialty === specialty.id
        && service.isPurchasable
        && ((!data.hideMyCompanyPrices && service.isAutoPrice) || getServicePrices(service.id).length > 0)
      ).length > 0
    ).sort(firstBy('specialty', {ignoreCase:true}));
  }, [data, deps]);
  const getServices = useCallback(() => {
    return deps.services.filter(service => true &&
      (false
        || (data.serviceText === defaultData.serviceText && service.idSpecialty === data.idSpecialty)
        || (data.serviceText !== defaultData.serviceText && ~service.service.toLowerCase().indexOf(data.serviceText.toLowerCase()))
      )
      && service.isPurchasable
      && ((!data.hideMyCompanyPrices && service.isAutoPrice) || getServicePrices(service.id).length > 0)
    ).sort(firstBy('service'), {ignoreCase:true});
  }, [data, deps]);
  const getServicePrices = (idService, showMyCompanyPrices = false) => deps.servicePrices.filter(servicePrice => true
    && parseInt(servicePrice.id) > 0
    && (servicePrice.idBeneficiary === data.idBeneficiary || ((!data.hideMyCompanyPrices || showMyCompanyPrices) && data.idBeneficiary !== deps.myCompany.id && servicePrice.idBeneficiary === deps.myCompany.id))
    && servicePrice.idBranch === data.idBranch
    && servicePrice.idService === idService
  ).sort(firstBy('isMyCompany', 'desc').thenBy('doctor'));

  const getReceptionsPrice = () => data.receptions.reduce((acc, reception, index) => acc + +getReceptionPrice(index), 0);
  const getReceptionPrice = index => data.receptions[index].services.reduce((acc, service) => acc + +service.price, 0);

  const getUpdatedReceptions = (prevState, receptions, service) => {
    const updatedService = getUpdatedService(prevState, service);

    if (service.isExclusive) {
      return [
        ...receptions,
        getUpdatedReception(prevState, updatedService),
      ].sort(firstBy('branch').thenBy('specialty').thenBy('doctor'));
    }

    const existingReceptionIndex = receptions.findIndex(reception =>
      reception.idBeneficiary === updatedService.idBeneficiary &&
      reception.idBranch === prevState.idBranch &&
      reception.idSpecialty === updatedService.idSpecialty &&
      reception.idDoctor === updatedService.idDoctor &&
      reception.idDoctorContract === updatedService.idDoctorContract &&
      reception.idBeneficiaryContract === updatedService.idBeneficiaryContract &&
      reception.services.every(service => !service.isExclusive)
    );

    if (existingReceptionIndex !== -1) {
      const services = [...receptions[existingReceptionIndex].services, updatedService].sort(firstBy('service'));
      const type = services.every(item => item.isBookable) ? 'booking' : services.every(item => item.isSchedulable) ? 'schedule' : services.every(item => item.isPurchasable) ? 'open' : null;

      return [
        ...receptions.slice(0, existingReceptionIndex),
        {
          ...receptions[existingReceptionIndex],
          type: type,
          scheduleDuration: services.reduce((acc, value) => acc + +value.duration, 0).toString(),
          services: services,
        },
        ...receptions.slice(existingReceptionIndex + 1)
      ];
    } else {
      return [
        ...receptions,
        getUpdatedReception(prevState, updatedService),
      ].sort(firstBy('branch').thenBy('specialty').thenBy('doctor'));
    }
  };
  const getUpdatedReception = (prevState, updatedService) => {
    const servicePrice = getServicePrices(updatedService.id).find(servicePrice => servicePrice.idBeneficiary === prevState.idBeneficiary) || getServicePrices(updatedService.id)[0];

    return {
      type: updatedService.isBookable ? 'booking' : updatedService.isSchedulable ? 'schedule' : updatedService.isPurchasable ? 'open' : null,
      idBeneficiary: servicePrice.idBeneficiary || '',
      idBranch: prevState.idBranch,
      branch: deps.branches.find(branch => branch.id === prevState.idBranch).branch,
      idSpecialty: updatedService.idSpecialty,
      specialty: deps.specialties.find(specialty => specialty.id === updatedService.idSpecialty).specialty,
      idDoctor: servicePrice.idDoctor || '',
      doctor: servicePrice.doctor || '',
      idDoctorContract: servicePrice.idDoctorContract,
      idBeneficiaryContract: servicePrice.idBeneficiaryContract,
      scheduleDate: null,
      scheduleTime: null,
      scheduleDuration: updatedService.duration,
      services: [updatedService],
    };
  };
  const getUpdatedService = (prevState, service) => {
    const servicePrice = getServicePrices(service.id).find(servicePrice => servicePrice.idBeneficiary === prevState.idBeneficiary) || getServicePrices(service.id)[0];

    return {
      ...service,
      idServicePrice: servicePrice.id,
      idBeneficiary: servicePrice.idBeneficiary,
      duration: servicePrice.duration,
      price: servicePrice.price,
      priceDefault: servicePrice.price,
      isPaidByCustomer: !Boolean(servicePrice.idBeneficiaryContract),
      idDoctor: servicePrice.idDoctor || '',
      doctor: servicePrice.doctor || '',
      idDoctorContract: servicePrice.idDoctorContract,
      idBeneficiaryContract: servicePrice.idBeneficiaryContract,
      receptionServiceActionValues: [],
    };
  };
  //#endregion

  //#region Handlers
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = () => () => {
    ac.ajax('getDashboard', {
      idBranch: data.idBranch,
      idBeneficiary: data.idBeneficiary,
      idCustomer: data.idCustomer,
    })
      .then(response => {
        setData(prevState => ({
          ...prevState,
          ...response.result.data,
        }));
        setDeps(prevState => ({
          ...prevState,
          ...response.result.dependencies,
        }));
        setDefs(prevState => ({
          ...prevState,
          ...response.result.defaultValues
        }));

        setIsLoaded(true);
      });
  };
  const handleBeneficiarySelect = () => (event, entity, reason) => {
    if (reason === 'selectOption') {
      setData(prevState => ({
        ...prevState,
        idBeneficiary: entity.id,
        beneficiary: entity,
        hideMyCompanyPrices: entity.id === deps.myCompany.id ? false : prevState.hideMyCompanyPrices,
      }));
      setAjaxSearchBeneficiary(defaultAjaxSearch);
    }
  };
  const handleCustomerSelect = () => (event, value, reason) => {
    if (value === null) { /** Clicked on X (clear value) */
      console.log(`idCustomer was cleared, so we clear data.idCustomer`);
      setData(prevState => ({
        ...prevState,
        idCustomer: defaultData.idCustomer,
        customer: defaultData.customer,
      }));
    } else if (value.id === '0') { /** Deschide fereastra de adaugare a clientului cu ID-ul `` si numele `value.name` */
      console.log(`idCustomer is '0', so we open a dialog to define a new customer named '${value.name}'`);
      setSelection({
        ...selection,
        entity: defs.customer,
        op: 'editCustomer',
      });
    } else { /** S-a ales din lista un client existent */
      console.log(`idCustomer is '${value.id}' and its name is '${value.name} ${value.surname}', so we have a selected customer`);
      setData(prevState => ({
        ...prevState,
        idCustomer: value.id,
        customer: value,
      }));
    }

    // if (reason === 'selectOption') {
    //   setData(prevState => ({
    //     ...prevState,
    //     idCustomer: value.id || defs.customer.id,
    //     customer: value,
    //   }));
    //   setAjaxSearchCustomer(defaultAjaxSearch);
    // }
  };
  const handleCustomerClear = () => {
    setData(prevState => ({
      ...prevState,
      idCustomer: defaultData.idCustomer,
      customer: defaultData.customer,
    }));
  };
  const handleServiceAdd = service => () => {
    setData(prevState => ({
      ...prevState,
      idService: service.id,
      receptions: getUpdatedReceptions(prevState, prevState.receptions, service),
    }));
  };
  const handleServiceRemove = idService => () => {
    setData(prevState => ({
        ...prevState,
        receptions: prevState.receptions
          .map(reception => ({
            ...reception,
            scheduleDuration: reception.services.filter(service => service.id !== idService).reduce((acc, value) => acc + +value.duration, 0).toString(),
            services: reception.services.filter(service => service.id !== idService),
          }))
          .filter(reception => reception.services.length > 0),
    }));
  };
  const handleServicePriceChange = (updatedService, servicePrice) => () => {
    if (updatedService.idServicePrice === servicePrice.id) return;

    setData(prevState => {
      const existingReceptionIndex = prevState.receptions.findIndex(reception =>
        reception.idBeneficiary === servicePrice.idBeneficiary &&
        reception.idBranch === prevState.idBranch &&
        reception.idSpecialty === servicePrice.idSpecialty &&
        reception.idDoctor === servicePrice.idDoctor &&
        reception.idDoctorContract === servicePrice.idDoctorContract &&
        reception.idBeneficiaryContract === servicePrice.idBeneficiaryContract &&
        reception.services.every(service => !service.isExclusive)
      );

      const newService = {
        ...updatedService,
        idServicePrice: servicePrice.id,
        idBeneficiary: servicePrice.idBeneficiary,
        duration: servicePrice.duration,
        price: servicePrice.price,
        priceDefault: servicePrice.price,
        isPaidByCustomer: !Boolean(servicePrice.idBeneficiaryContract),
        idDoctor: servicePrice.idDoctor,
        doctor: servicePrice.doctor,
        idDoctorContract: servicePrice.idDoctorContract,
        idBeneficiaryContract: servicePrice.idBeneficiaryContract,
        receptionServiceActionValues: [],
      };

      let updatedReceptions;

      if (existingReceptionIndex !== -1) {
        updatedReceptions = prevState.receptions
          .map((reception, index) => {
            if (index === existingReceptionIndex) {
              return {
                ...reception,
                scheduleDuration: [...reception.services.filter(item => item.id !== newService.id), newService].reduce((acc, value) => acc + +value.duration, 0).toString(),
                services: [...reception.services.filter(item => item.id !== newService.id), newService].sort(firstBy('service')),
              };
            } else {
              return {
                ...reception,
                scheduleDuration: reception.services.filter(s => s.id !== updatedService.id).reduce((acc, value) => acc + +value.duration, 0).toString(),
                services: reception.services.filter(s => s.id !== updatedService.id),
              };
            }
          })
          .filter(reception => reception.services.length > 0);
      } else {
        updatedReceptions = prevState.receptions
          .map(reception => ({
            ...reception,
            scheduleDuration: reception.services.filter(s => s.id !== updatedService.id).reduce((acc, value) => acc + +value.duration, 0).toString(),
            services: reception.services.filter(s => s.id !== updatedService.id),
          }))
          .filter(reception => reception.services.length > 0)
          .concat({
            type: newService.isBookable ? 'booking' : newService.isSchedulable ? 'schedule' : newService.isPurchasable ? 'open' : null,
            idBeneficiary: deps.servicePrices.find(servicePrice => servicePrice.id === newService.idServicePrice).idBeneficiary,
            idBranch: prevState.idBranch,
            branch: deps.branches.find(branch => branch.id === prevState.idBranch).branch,
            idSpecialty: servicePrice.idSpecialty,
            specialty: deps.specialties.find(specialty => specialty.id === servicePrice.idSpecialty).specialty,
            idDoctor: servicePrice.idDoctor,
            doctor: servicePrice.doctor,
            idDoctorContract: servicePrice.idDoctorContract,
            idBeneficiaryContract: servicePrice.idBeneficiaryContract,
            scheduleDate: null,
            scheduleTime: null,
            scheduleDuration: [newService].reduce((acc, value) => acc + +value.duration, 0).toString(),
            services: [newService],
          })
          .sort(firstBy('branch').thenBy('specialty').thenBy('doctor'));
      }

      return {
        ...prevState,
        receptions: updatedReceptions,
      };
    });
  };
  //#endregion

  //#region Renderer
  if (isLoaded === false) {
    return null;
  }

  return (
    <Container maxWidth='xl' fixed>
      <Typography variant='subtitle2'>Dashboard</Typography>
      <Typography variant='subtitle2'>{JSON.stringify(ac.user)}</Typography>

      {
      //#region Fresh installation wizzards
      deps.myCompany === false
        ? <Wizard01MyCompany
            data={{
              ...defs.beneficiary,
              idCountry: '1',
              isMyCompany: true,
            }}
            deps={deps}
            defs={defs}
            onConfirm={handleReload()}
          />
        : deps.branches?.length === 0
          ? <Wizard02MyBranches
              data={{
                ...defs.branch,
                idBeneficiary: deps.myCompany.id,
                address: deps.myCompany.address,
                isDefault: true,
              }}
              deps={deps}
              defs={defs}
              onConfirm={handleReload()}
            />
          : null
      //#endregion
      }

      <Grid container spacing={1} my={1}>
        {
        //#region Branch select
        }
        <Grid item xs={12} sm={2} sx={{alignItems: 'flex-start'}}>
          <TextField select
            disabled={ac.isLoading}
            label='Punct de lucru'
            name='idBranch'
            value={data.idBranch}
            onChange={handleDataChange()}
          >
            {getBranches().length > 1 &&
              <MenuItem value='0' sx={{fontStyle: 'italic', color: 'gray'}}>Toate punctele de lucru</MenuItem>
            }
            {getBranches().map(item => (
              <MenuItem key={item.id} value={item.id}>{item.branch}</MenuItem>
            ))}
          </TextField>
        </Grid>
        {
        //#endregion
        }

        {
        //#region Beneficiary search & details
        }
        <Grid item xs={12} sm={5} sx={{alignItems: 'flex-start'}}>
          <Autocomplete freeSolo
            disabled={ac.isLoading || (data.receptions.some(reception => reception.idBeneficiary !== deps.myCompany.id) /*data.idBeneficiary !== deps.myCompany.id && data.receptions.length > 0*/)}
            renderInput={params => (<TextField {...params} error={data.idBeneficiary === defaultData.idBeneficiary} label='Caută beneficiar...' />)}
            getOptionLabel={option => option ? option.beneficiary : ''}
            renderOption={(props, option) =>
              <ListItem {...props}>
                <ListItemText
                  primary={`${option.beneficiary} [CUI: ${option.taxCode}]`}
                  secondary={<Description text={option.description ? option.description : ' '} />}
                />
              </ListItem>
            }
            noOptionsText='Nu s-a gasit nici un beneficiar'
            options={getBeneficiaries()}
            filterOptions={x => x}
            // groupBy={option => option.specialty}
            inputValue={ajaxSearchBeneficiary.text}
            onInputChange={(event, value) => setAjaxSearchBeneficiary({...ajaxSearchBeneficiary, text: value})}
            onChange={handleBeneficiarySelect()}
          />
        </Grid>
        {
        //#endregion
        }

        {
        //#region Customer search & details
        }
        <Grid item xs={12} sm={5} sx={{alignItems: 'flex-start', overflow: 'hidden'}}>
          {data.idCustomer === defs.customer.id
            ? <Autocomplete freeSolo
                disabled={ac.isLoading}
                renderInput={params => (<TextField {...params} error={data.idCustomer === defaultData.idCustomer} label='Caută client...' />)}
                getOptionLabel={option => option ? `${option.surname} ${option.name} [${option.sex}: ${option.cnp}]` : ''}
                renderOption={(props, option) =>
                  <ListItem {...props}>
                    <ListItemText
                      primary={option.id === '0' ? 'Adauga un client nou...' : `${option.surname} ${option.name}`}
                      secondary={option.id === '0' ? null : <Description text={`Sex: ${option.sex}; CNP: ${option.cnp}`} />}
                    />
                  </ListItem>
                }
                noOptionsText='Nu s-a gasit nici un client'
                options={getCustomers().concat({id: '0'})}
                filterOptions={x => x}
                inputValue={ajaxSearchCustomer.text}
                onInputChange={(event, value) => setAjaxSearchCustomer({...ajaxSearchCustomer, text: value})}
                onChange={handleCustomerSelect()}
                // onChange={(evt, newValue) => handleDataChange(newValue, 'idCustomer', 'addCustomer')}
              />
            : <Box sx={{flexGrow: 1, width: '100%', px: 1, py: .5, border: '1px solid darkgray', borderRadius: '4px'}}>
                <Box sx={{display: 'flex', alignItems: 'center', minHeight: '38px'}}>
                  {data.options.customerPanelExpanded
                    ? <IconButton size='small' sx={{mr: 1}}
                        onClick={() => handleDataChange('options')({target: {name: 'customerPanelExpanded', type: 'component', value: false}})}
                      >
                        <ExpandLessIcon fontSize='small' />
                      </IconButton>
                    : <IconButton size='small' sx={{mr: 1}}
                        onClick={() => handleDataChange('options')({target: {name: 'customerPanelExpanded', type: 'component', value: true}})}
                      >
                        <ExpandMoreIcon fontSize='small' />
                      </IconButton>
                  }

                  <Box sx={{flexShrink: 1, display: 'flex', flexDirection: 'column'}}>
                    <Link underline='hover' sx={{cursor: 'pointer'}}
                      onClick={handleSelectionChange({entity: data.customer, op: 'editCustomer'})}
                    >
                      <Typography variant='subtitle1' sx={{lineHeight: '20px', fontWeight: 'bold'}}>{data.customer.name} {data.customer.surname}</Typography>
                    </Link>
                    <Box sx={{display: 'flex', gap: .5}}>
                      <Typography variant='body2' sx={{lineHeight: '18px', fontWeight: 'bold'}}>{data.customer.cnp}</Typography>
                      {data.customer.sex === 'M'
                        ? <MaleIcon color='secondary' sx={{fontSize: 18}} />
                        : data.customer.sex === 'F'
                          ? <FemaleIcon color='secondary' sx={{fontSize: 18}} />
                          : <TransgenderIcon color='secondary' sx={{fontSize: 18}} />
                      }
                    </Box>
                  </Box>

                  <Box sx={{flexGrow: 1}} />

                  {
                    //#region Customer panel collapsed
                  }
                    {!data.options.customerPanelExpanded &&
                      <Box sx={{display: 'flex', gap: .5}}>
                        {data.customer.description &&
                          <Tooltip title={data.customer.description}>
                            <Box>
                              <IconButton size='small' color='info'
                                disabled={ac.isLoading}
                                onClick={handleSelectionChange({entity: data.customer, op: 'editCustomer'})}
                              >
                                <InfoIcon fontSize='small' />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        }

                        {data.customer.birthDate.isBirthday() &&
                          <Tooltip title='La multi ani!'>
                            <Box>
                              <IconButton size='small' color='success'
                                disabled={ac.isLoading}
                                // onClick={handleSelectionChange({entity: {...defs.customerAddress, idCustomer: data.customer.id}, op: 'editCustomerAddress'})}
                              >
                                <CakeIcon fontSize='small' />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        }

                        <Tooltip title='Acest client beneficiaza de servicii preplatite'>
                          <Box>
                            <IconButton size='small' color='success'
                              disabled={ac.isLoading}
                              // onClick={handleSelectionChange({entity: {...defs.customerAddress, idCustomer: data.customer.id}, op: 'editCustomerAddress'})}
                            >
                              <FreeBreakfastIcon fontSize='small' />
                            </IconButton>
                          </Box>
                        </Tooltip>

                        <Tooltip title={!Boolean(data.gdprAgreement?.id) ? 'Acest client nu are acordul GDPR semnat!' : getGdprAgreementType().isOnline ? 'Acest client are doar acordul GDPR online acceptat' : 'Acest client are acordul GDPR semnat'}>
                          <Box>
                            <IconButton size='small' color={!Boolean(data.gdprAgreement?.id) ? 'error' : getGdprAgreementType().isOnline ? 'warning' : 'success'}
                              disabled={ac.isLoading}
                              onClick={handleSelectionChange({entity: {...data.gdprAgreement, idCustomer: data.customer.id}, op: 'editGdprAgreement'})}
                            >
                              <FingerprintIcon fontSize='small' />
                            </IconButton>
                          </Box>
                        </Tooltip>

                        <Tooltip title='Acest client are servicii neplatite'>
                          <Box>
                            <IconButton size='small' color='error'
                              disabled={ac.isLoading}
                              // onClick={handleSelectionChange({entity: {...defs.customerAddress, idCustomer: data.customer.id}, op: 'editCustomerAddress'})}
                            >
                              <PaidIcon fontSize='small' />
                            </IconButton>
                          </Box>
                        </Tooltip>

                        <Tooltip title='Acest client are note atasate'>
                          <Box>
                            <IconButton size='small' color='warning'
                              disabled={ac.isLoading}
                              // onClick={handleSelectionChange({entity: {...defs.customerAddress, idCustomer: data.customer.id}, op: 'editCustomerAddress'})}
                            >
                              <PendingActionsIcon fontSize='small' />
                            </IconButton>
                          </Box>
                        </Tooltip>

                        {data.customer.addresses.length === 0 &&
                          <Tooltip title='Nu exista adrese definite pentru acest client'>
                            <Box>
                              <IconButton color='error' sx={{ml: 1}}
                                disabled={ac.isLoading}
                                onClick={handleSelectionChange({entity: {...defs.customerAddress, idCustomer: data.customer.id}, op: 'editCustomerAddress'})}
                              >
                                <ContactEmergencyIcon color='warning' />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        }

                        {data.customer.phones.length === 0 &&
                          <Tooltip title='Nu exista numere de telefon definite pentru acest client'>
                            <Box>
                              <IconButton color='error' sx={{ml: 1}}
                                disabled={ac.isLoading}
                                onClick={handleSelectionChange({entity: {...defs.customerPhone, idCustomer: data.customer.id}, op: 'editCustomerPhone'})}
                              >
                                <ContactPhoneIcon color='warning' />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        }

                        {data.customer.emails.length === 0 &&
                          <Tooltip title='Nu exista adrese de email definite pentru acest client'>
                            <Box>
                              <IconButton color='error' sx={{ml: 1}}
                                disabled={ac.isLoading}
                                onClick={handleSelectionChange({entity: {...defs.customerEmail, idCustomer: data.customer.id}, op: 'editCustomerEmail'})}
                              >
                                <ContactMailIcon color='warning' />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        }
                      </Box>
                    }

                    <Tooltip title='Deselecteaza acest client'>
                      <IconButton size='small' color='error' sx={{ml: 1}}
                        onClick={handleCustomerClear}
                      >
                        <ClearIcon fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  {
                    //#endregion
                  }
                </Box>

                {
                  //#region Customer panel expanded
                }
                  {data.options.customerPanelExpanded &&
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item><Chip icon={<CakeIcon />} label='La multi ani!' variant='filled' size='small' color='success' /></Grid>
                          <Grid item><Chip icon={<FreeBreakfastIcon />} label='Servicii preplatite' variant='filled' size='small' color='success' /></Grid>
                          <Grid item><Chip icon={<FingerprintIcon />} label='GDPR' variant='filled' size='small' color='error' /></Grid>
                          <Grid item><Chip icon={<PaidIcon />} label='Servicii neplatite' variant='filled' size='small' color='error' /></Grid>
                          <Grid item><Chip icon={<PendingActionsIcon />} label='Note' variant='filled' size='small' color='warning' /></Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Description text={data.customer.description} />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField select sx={{'& .MuiSelect-select': {display: 'block'}}}
                          disabled={ac.isLoading}
                          label='Adresa'
                          placeholder='Adresa'
                          name='idCustomerAddress'
                          value={data.customer.idCustomerAddress}
                          onChange={handleDataChange('customer')}
                        >
                          {data.customer.addresses.map(address => (
                            <MenuItem key={address.id} value={address.id}>{`${address.city}, ${address.street || '-'} nr. ${address.streetNumber || '-'}, bl. ${address.building || '-'}, sc. ${address.staircase || '-'}, et. ${address.floor || '-'}, ap. ${address.apartment || '-'}, jud. ${address.county}, ${address.country}`}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField select sx={{'& .MuiSelect-select': {display: 'block'}}}
                          disabled={ac.isLoading}
                          label='Act de identitate'
                          placeholder='Act de identitate'
                          name='idCustomerIdentityCard'
                          value={data.customer.idCustomerIdentityCard}
                          onChange={handleDataChange('customer')}
                        >
                          {data.customer.identityCards.map(identityCard => (
                            <MenuItem key={identityCard.id} value={identityCard.id}>{`${deps.identityCardTypes.find(item => item.id === identityCard.idIdentityCardType).identityCardType}: ${identityCard.serial || '-'} ${identityCard.number || '-'}${identityCard.validTo ? ` (exp. ${new Date(identityCard.validTo).format()})` : ''}`}</MenuItem>
                          ))}
                        </TextField>
                        {new Date(data.customer.identityCards.find(item => item.id === data.customer.idCustomerIdentityCard)?.validTo || 9999999999999) < new Date() &&
                          <Tooltip title='Acest act de identitate este expirat! Click pentru a adauga un act de identitate valabil'>
                            <Box>
                              <IconButton color='error' sx={{ml: 1}}
                                disabled={ac.isLoading}
                                onClick={handleSelectionChange({entity: {...defs.customerIdentityCard, idCustomer: data.customer.id}, op: 'editCustomerIdentityCard'})}
                              >
                                <WarningIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        }
                      </Grid>

                      <Grid item xs={12}>
                        <TextField select sx={{'& .MuiSelect-select': {display: 'block'}}}
                          disabled={ac.isLoading}
                          label='Telefon'
                          placeholder='Telefon'
                          name='idCustomerPhone'
                          value={data.customer.idCustomerPhone}
                          onChange={handleDataChange('customer')}
                        >
                          {data.customer.phones.map(phone => (
                            <MenuItem key={phone.id} value={phone.id}>{phone.phone}</MenuItem>
                          ))}
                        </TextField>
                        {data.gdprAgreement?.allowSms &&
                          <Tooltip title='Trimite un SMS'>
                            <Box>
                              <IconButton color='primary' sx={{ml: 1}}
                                disabled={ac.isLoading || data.customer.idCustomerPhone === defs.customer.idCustomerPhone}
                                onClick={handleSelectionChange({entity: data.customer, op: 'sendCustomSms'})}
                              >
                                <SmsIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        }
                      </Grid>

                      <Grid item xs={12}>
                        <TextField select sx={{'& .MuiSelect-select': {display: 'block'}}}
                          disabled={ac.isLoading}
                          label='Email'
                          placeholder='Email'
                          name='idCustomerEmail'
                          value={data.customer.idCustomerEmail}
                          onChange={handleDataChange('customer')}
                        >
                          {data.customer.emails.map(email => (
                            <MenuItem key={email.id} value={email.id}>{email.email}</MenuItem>
                          ))}
                        </TextField>
                        {data.gdprAgreement?.allowEmail &&
                          <Tooltip title='Trimite un email'>
                            <Box>
                              <IconButton color='primary' sx={{ml: 1}}
                                disabled={ac.isLoading || data.customer.idCustomerEmail === defs.customer.idCustomerEmail}
                                onClick={handleSelectionChange({entity: data.customer, op: 'sendCustomEmail'})}
                              >
                                <EmailIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                  }
                {
                  //#endregion
                }
              </Box>
          }
        </Grid>
        {
        //#endregion
        }
      </Grid>

      <Grid container spacing={1}>
        {
        //#region Specialties list
        }
        <Grid item xs={12} sm={2} sx={{flexDirection: 'column', /*alignItems: 'flex-start',*/ height: 'calc(100vh - 200px)'}}>
          <Box sx={{width: '100%', backgroundColor: 'success.light', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}>
            <Typography variant='subtitle1' sx={{px: 2, py: .5, fontWeight: 'bold', color: 'info.contrastText'}}>Specialitati</Typography>
          </Box>
          <List dense sx={{width: '100%', height: '-webkit-fill-available', py: 0, border: '1px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', overflowY: 'auto'}}>
            {getSpecialties().map(specialty => (
              <ListItemButton key={specialty.id} divider disableTouchRipple sx={{cursor: 'pointer'}}
                selected={specialty.id === data.idSpecialty}
                onClick={() => setData(prevState => ({...prevState, idSpecialty: specialty.id}))}
              >
                <ListItemText disableTypography
                  primary={<Typography sx={{fontWeight: 'bold'}}>{specialty.specialty}</Typography>}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        {
        //#endregion
        }

        {
        //#region Services list
        }
        <Grid item xs={12} sm={5} sx={{flexDirection: 'column', /*alignItems: 'flex-start',*/ height: 'calc(100vh - 200px)'}}>
          <Box sx={{width: '100%', backgroundColor: 'success.light', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}>
            <Typography variant='subtitle1' sx={{px: 2, py: .5, fontWeight: 'bold', color: 'info.contrastText'}}>Servicii disponibile</Typography>
          </Box>
          <List dense sx={{width: '100%', height: '-webkit-fill-available', py: 0, border: '1px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', overflowY: 'scroll'}}>
            <ListSubheader sx={{display: 'flex', alignItems: 'center', my: '8px'}}>
              <TextField variant='outlined' sx={{flexGrow: 1, mr: 1}}
                InputProps={{
                  endAdornment: data.serviceText !== defaultData.serviceText
                    ? <InputAdornment position='end'>
                        <IconButton edge='end' size='small' color='error'
                          onClick={() => setData(prevState => ({...prevState, serviceText: defaultData.serviceText}))}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    : null,
                }}
                disabled={ac.isLoading}
                error={data.serviceText !== defaultData.serviceText}
                label='Cauta serviciu...'
                placeholder='Cauta serviciu...'
                name='serviceText'
                value={data.serviceText}
                onChange={evt => setData(prevState => ({...prevState, [evt.target.name]: evt.target.value}))}
              />
              <Tooltip title='Ascunde preturile de lista si afiseaza doar preturile negociate cu partenerul selectat'>
                <Box>
                  <ToggleButton size='small' color='error'
                    disabled={ac.isLoading || data.idBeneficiary === deps.myCompany.id}
                    selected={data.hideMyCompanyPrices}
                    name='hideMyCompanyPrices'
                    value='hideMyCompanyPrices'
                    onChange={() => setData(prevState => ({...prevState, hideMyCompanyPrices: !prevState.hideMyCompanyPrices}))}
                  >
                    <LocalOfferIcon />
                  </ToggleButton>
                </Box>
              </Tooltip>
            </ListSubheader>
            {data.idSpecialty === defaultData.idSpecialty && data.serviceText === defaultData.serviceText &&
              <ListItem>
                <ListItemText disableTypography
                  primary={<Typography variant='subtitle2' color='error'>Selectati o specialitate din stanga sau cautati un serviciu folosing campul de mai sus</Typography>}
                />
              </ListItem>
            }
            {data.serviceText !== defaultData.serviceText && getServices().length === 0 &&
              <ListItem>
                <ListItemText disableTypography
                  primary={<Typography variant='subtitle2' color='error'>Nu exista nici un serviciu care sa corespunda cautarii d-voastra</Typography>}
                />
              </ListItem>
            }
            {getServices().map(service => (
              <ListItemButton key={service.id} divider disableTouchRipple sx={{cursor: 'pointer'}}
                autoFocus={data.idService === service.id}
                disabled={data.receptions.map(reception => reception.services.map(item => item.id)).flat().find(item => item === service.id) !== undefined}
                selected={data.idService === service.id}
                onClick={handleServiceAdd(service)}
              >
                <ListItemText disableTypography
                  primary={<Highlighted highlight={data.serviceText} variant='subtitle2' sx={{flex: '50% 1 1'}}>{service.service}</Highlighted>}
                  secondary={getServicePrices(service.id).map(servicePrice => (
                    <Grid key={servicePrice.id} container spacing={1}>
                      <Grid item sx={{flex: '144px 1 1', justifyContent: 'flex-end'}}>
                        {servicePrice.idDoctorContract
                          ? <Typography sx={{textAlign: 'right', fontStyle: 'italic', color: 'secondary.dark', whiteSpace: 'nowrap'}}>
                              {servicePrice.doctor}
                              {servicePrice.datasetValue && <Typography component='span' sx={{display: 'inline', fontWeight: 'bold', whiteSpace: 'nowrap'}}> ({servicePrice.datasetValue})</Typography>}
                            </Typography>
                          : servicePrice.idBeneficiaryContract
                            ? <Typography sx={{textAlign: 'right', fontStyle: 'italic', color: 'success.dark', whiteSpace: 'nowrap'}}>
                                {servicePrice.beneficiary}
                                {servicePrice.datasetValue && <Typography component='span' sx={{display: 'inline', fontWeight: 'bold', whiteSpace: 'nowrap'}}> ({servicePrice.datasetValue})</Typography>}
                              </Typography>
                            : <Typography sx={{textAlign: 'right', fontStyle: 'italic', color: 'primary.dark', whiteSpace: 'nowrap'}}>
                                {'Pret de lista'}
                                {servicePrice.datasetValue && <Typography component='span' sx={{display: 'inline', fontWeight: 'bold', whiteSpace: 'nowrap'}}> ({servicePrice.datasetValue})</Typography>}
                              </Typography>
                        }
                      </Grid>
                      <Grid item sx={{flex: '120px 0 0', justifyContent: 'flex-end'}}>
                        <Typography sx={{fontWeight: 'bold', color: servicePrice.idDoctorContract ? 'secondary.dark' : servicePrice.idBeneficiaryContract ?  'success.dark': 'primary.dark', whiteSpace: 'nowrap'}}>{servicePrice.price.toCurrency()}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        {
        //#endregion
        }

        {
        //#region Selected services list
        }
        <Grid item xs={12} sm={5} sx={{flexDirection: 'column', /*alignItems: 'flex-start',*/ height: 'calc(100vh - 200px)'}}>
          <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', backgroundColor: 'success.light', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}>
            <Typography variant='subtitle1' sx={{px: 2, py: .5, fontWeight: 'bold', color: 'info.contrastText'}}>Servicii selectate</Typography>
            <Typography variant='subtitle1' sx={{px: 2, py: .5, fontWeight: 'bold', color: 'info.contrastText'}}>{getReceptionsPrice().toString().toCurrency()}</Typography>
          </Box>

          <List dense sx={{width: '100%', height: '-webkit-fill-available', py: 0, border: '1px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', overflowY: 'auto'}}>
            {data.receptions.length === 0 &&
              <ListSubheader>
                <Typography variant='subtitle2' color='error'>Nu aveti nici un serviciu selectat!</Typography>
              </ListSubheader>
            }
            {data.receptions.map((reception, index) => (
              <ListSubheader key={index}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: theme => theme.palette.warning.main, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{reception.branch}</Typography>
                  <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: theme => theme.palette.secondary.main, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{reception.specialty}</Typography>
                  <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: theme => theme.palette.success.main, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{reception.doctor}</Typography>
                  <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: theme => theme.palette.info.main, whiteSpace: 'nowrap'}}>{getReceptionPrice(index).toString().toCurrency()}</Typography>
                </Box>

                {reception.services.map(service => (
                  <ListItemButton key={service.id} divider disableGutters disableTouchRipple sx={{cursor: 'pointer'}}
                    autoFocus={data.idService === service.id}
                    selected={data.idService === service.id}
                    onClick={() => {
                      setData(prevState => ({
                        ...prevState,
                        idSpecialty: service.idSpecialty,
                        serviceText: defaultData.serviceText,
                        idService: service.id,
                      }));
                    }}
                  >
                    <ListItemText disableTypography
                      primary={<Typography variant='subtitle2' sx={{flex: '50% 1 1', mr: 7}}>{service.service}</Typography>}
                      secondary={
                        <Box sx={{mr: 7}}>
                          {getServicePrices(service.id, true).map(servicePrice => (
                            <Grid key={servicePrice.id} container spacing={1}>
                              <Grid item sx={{flexGrow: 1, justifyContent: 'flex-end'}}>
                                {servicePrice.idDoctorContract
                                  ? <Typography sx={{textAlign: 'right', fontStyle: 'italic', color: 'secondary.dark', whiteSpace: 'nowrap'}}>
                                      {servicePrice.doctor}
                                      {servicePrice.datasetValue && <Typography component='span' sx={{display: 'inline', fontWeight: 'bold', whiteSpace: 'nowrap'}}> ({servicePrice.datasetValue})</Typography>}
                                    </Typography>
                                  : servicePrice.idBeneficiaryContract
                                    ? <Typography sx={{textAlign: 'right', fontStyle: 'italic', color: 'success.dark', whiteSpace: 'nowrap'}}>
                                        {servicePrice.beneficiary}
                                        {servicePrice.datasetValue && <Typography component='span' sx={{display: 'inline', fontWeight: 'bold', whiteSpace: 'nowrap'}}> ({servicePrice.datasetValue})</Typography>}
                                      </Typography>
                                    : <Typography sx={{textAlign: 'right', fontStyle: 'italic', color: 'primary.dark', whiteSpace: 'nowrap'}}>
                                        {'Pret de lista'}
                                        {servicePrice.datasetValue && <Typography component='span' sx={{display: 'inline', fontWeight: 'bold', whiteSpace: 'nowrap'}}> ({servicePrice.datasetValue})</Typography>}
                                      </Typography>
                                }
                              </Grid>
                              <Grid item sx={{flex: '120px 0 0', justifyContent: 'flex-end'}}>
                                <FormControlLabel labelPlacement='start' sx={{flex: '120px 0 0'}}
                                  label={
                                    <Typography sx={{flex: '96px 0 0', textAlign: 'right', fontWeight: 'bold', color: servicePrice.idDoctorContract ? 'secondary.dark' : servicePrice.idBeneficiaryContract ?  'success.dark': 'primary.dark', whiteSpace: 'nowrap'}}>{servicePrice.price.toCurrency()}</Typography>
                                  }
                                  control={
                                    <Checkbox size='small' disableRipple sx={{'&.MuiCheckbox-root': {py: 0}}}
                                      disabled={ac.isLoading}
                                      name='idServicePrice'
                                      checked={service.idServicePrice === servicePrice.id}
                                      onChange={handleServicePriceChange(service, servicePrice)}
                                    />
                                  }
                                  onClick={evt => evt.stopPropagation()}
                                />
                              </Grid>
                            </Grid>
                          ))}
                          {service.description &&
                            <Description text={service.description} />
                          }
                        </Box>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton color='error'
                        onClick={handleServiceRemove(service.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ))}
              </ListSubheader>
            ))}
          </List>

          <Button sx={{mt: 1}} size='large'
            disabled={data.receptions.length === 0}
            onClick={() => setData(prevState => ({...prevState, options: {...prevState.options, view: 'serviceActionsBeforeOpen'}}))}
          >Deschide / programeaza serviciile selectate</Button>
        </Grid>
        {
        //#endregion
        }

        {
        //#region ToDo, glitches and other infos
        }
        <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1}}>
          <Box sx={{mt: 2}}>
            <Typography variant='subtitle2'>Glitch:</Typography>
            <Typography color='secondary'>Nu sunt afisate preturile in baza contractelor cu beneficiarii in ServicePricesForMyCompany si ServicePricesForSpecialty</Typography>
            <Typography color='secondary'>La adaugarea unei tari sau judet, nu se golesc listele care depind de aceste campuri</Typography>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography variant='subtitle2'>ToDo:</Typography>
            <Typography color='secondary'>serviceItems.values - autocomplete with chips</Typography>
            <Typography color='secondary'>service forms designer and PDF generator</Typography>
            <Typography color='secondary'>standard entity dialog - Undo / Redo capabilities</Typography>
            <Typography color='secondary'></Typography>
            <Typography color='secondary'></Typography>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography variant='subtitle2'>Beneficiari:</Typography>
            <Typography>Implicit se vor afisa doar beneficiarii care au cel putin un contract valabil. Sunmed SRL reprezinta preturile "la liber".</Typography>
            <Typography>Daca avem mai mult de 20 de beneficiari, probabil ar trebui sa implementam un "Cauta beneficiar...".</Typography>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography variant='subtitle2'>Client neselectat:</Typography>
            <Typography>Lista serviciilor disponibile. De evidentiat serviciile care sunt disponibile in baza unui contract cu beneficiarul selectat, daca nu este vorba de myCompany.</Typography>
            <Typography>Daca este vorba de myCompany, preturile afisate vor fi cele valabile doar pentru punctul de lucru selectat</Typography>
            <Typography>De asemenea, dupa selectarea unui serviciu, daca avem pret negociat si pret "la liber" pentru acesta, sa dam posibilitatea utilizatorului sa selecteze cine va plati (beneficiarul sau clientul).</Typography>
            <Typography>Pentru serviciile selectate, daca este nevoie de prezenta unui medic, de ales medicul in baza perechii: specialitate asociata serviciului / specialitate contract medic cu punctul de lucru selectat. Apoi afisam pretul serviciului in functie de medicul si punctul de lucru selectat.</Typography>
            <Typography>In josul paginii, se afiseaza implicit toate programarile din ziua curenta, existand posibilitatea de a schimba ziua (sau doar cele cu ora programata depasita). Pentru afisarea tuturor programarilor vom avea o pagina separata.</Typography>
          </Box>
          <Box sx={{mt: 2}}>
            <Typography variant='subtitle2'>Client selectat:</Typography>
            <Typography>De afisat daca are task-uri definite global sau la nivel de utilizator.</Typography>
            <Typography>De afisat daca nu are accept GDPR.</Typography>
            <Typography>De afisat daca valabilitatea actelor de identitate a expirat.</Typography>
            <Typography>De afisat daca are receptii deschise si status-ul + pretul acestora.</Typography>
            <Typography>De afisat daca are programari facute de el sau facute de catre alti beneficiari si de transformat in receptii la alegerea utilizatorului.</Typography>
            <Typography>Lista serviciilor disponibile. Identic cu descrierea de la capitolul anterior <strong>Client neselectat</strong>.</Typography>
            <Typography>De afisat toate receptiile inchise si deschise (istoricul sau dosarul pacientului).</Typography>
          </Box>
        </Grid>
        {
        //#endregion
        }
      </Grid>

      {
      //#region Add/edit customer
      Boolean(selection.entity) && selection.op === 'editCustomer' &&
        <Customer
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={(response, closeDialog = true) => {
            console.log('response:', response);
            setData(prevState => ({
              ...prevState,
              idCustomer: response.result.data.customer.id,
              customer: response.result.data.customer,
            }));
            if (closeDialog) {
              handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op});
            }
          }}
        />
      //#endregion
      }

      {
      //#region Add/edit customer GDPR agreement type
      Boolean(selection.entity) && selection.op === 'editGdprAgreement' &&
        <GdprAgreement
          customer={`${data.customer.name} ${data.customer.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData({...data, gdprAgreement: response.result.data.gdprAgreement});
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      //#endregion
      }

      {
      //#region Add/edit customer address
      Boolean(selection.entity) && selection.op === 'editCustomerAddress' &&
        <CustomerAddress
          customer={`${data.customer.name} ${data.customer.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData({...data, customer: response.result.data.customer});
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
        //#endregion
      }

      {
      //#region Add/edit customer identity card
      Boolean(selection.entity) && selection.op === 'editCustomerIdentityCard' &&
        <CustomerIdentityCard
          customer={`${data.customer.name} ${data.customer.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData({...data, customer: response.result.data.customer});
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      //#endregion
      }

      {
      //#region Add/edit customer phone
      Boolean(selection.entity) && selection.op === 'editCustomerPhone' &&
        <CustomerPhone
          customer={`${data.customer.name} ${data.customer.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData({...data, customer: response.result.data.customer});
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      //#endregion
      }

      {
      //#region Add/edit customer email
      Boolean(selection.entity) && selection.op === 'editCustomerEmail' &&
        <CustomerEmail
          customer={`${data.customer.name} ${data.customer.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData({...data, customer: response.result.data.customer});
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      //#endregion
      }

      {
      //#region data.options.view === 'serviceActionsBeforeOpen'
      data.options.view === 'serviceActionsBeforeOpen' &&
        <ServiceActionsBeforeOpen
          value={data.receptions}
          deps={{
            idBranch: data.idBranch,
            myCompany: deps.myCompany,
            servicePrices: deps.servicePrices,
          }}
          onLoad={evt => {
            // console.log('ServiceActionsBeforeOpen.onLoad:', evt.target.data);
            setData(prevState => ({
              ...prevState,
              receptions: prevState.receptions.map(reception => ({
                ...reception,
                services: reception.services.map(service => ({
                  ...service,
                  childServices: evt.target.data.childServices
                    .filter(childService => childService.idServicePack === service.id)
                    .map(childService => ({
                      ...childService,
                      isPostponed: false,
                      isRemoved: false,
                      isAdded: false,
                    })),
                  receptionServiceActionValues: evt.target.data.receptionServiceActionValues
                    .filter(receptionServiceActionValue => receptionServiceActionValue.idService === service.id)
                    .map(receptionServiceActionValue => ({
                      ...receptionServiceActionValue,
                      idDatasetValue: service.isMultiPrice && service.idDatasetMultiPrice === receptionServiceActionValue.idDataset
                        ? deps.servicePrices.find(servicePrice => servicePrice.id === service.idServicePrice).idDatasetValue
                        : receptionServiceActionValue.idDatasetValue,
                      value: service.isMultiPrice && service.idDatasetMultiPrice === receptionServiceActionValue.idDataset
                        ? deps.servicePrices.find(servicePrice => servicePrice.id === service.idServicePrice).datasetValue
                        : receptionServiceActionValue.value,
                      isLocked: service.isMultiPrice && service.idDatasetMultiPrice === receptionServiceActionValue.idDataset,
                    })),
                })),
              })),
            }));
          }}
          onChange={evt => {
            // console.log('ServiceActionsBeforeOpen.onChange:', evt.target.data);
            setData(prevState => ({
              ...prevState,
              receptions: evt.target.data,
            }));
          }}
          onCancel={() => setData(prevState => ({...prevState, options: {...prevState.options, view: 'servicesSelect'}}))}
          onConfirm={response => {
            console.log(response);
          }}
        />
      //#endregion
      }
    </Container>
  );
  //#endregion
};

export default Dashboard;