import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import Beneficiary from './Beneficiary';
import DisableBeneficiary from './DisableBeneficiary';
import EnableBeneficiary from './EnableBeneficiary';

const defaultFilter = {
  text: '',
};
const defaultDeps = {};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Beneficiaries = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getBeneficiaries')
      .then(response => {
        if (response.status.ok) {
          setData(response.result.data.beneficiaries);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Getters
   */
  const getDefaultFilter = () => defaultFilter;

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleFilterReset = () => () => {
    setFilter(getDefaultFilter());
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = (response, newSelection = null) => {
    setData(response.result.data.beneficiaries);
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.beneficiaries.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={6} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />

        <Tooltip title='Reseteaza filtrele la valoarile initiale'>
          <div>
            <IconButton color='error'
              disabled={JSON.stringify(filter) === JSON.stringify(getDefaultFilter())}
              onClick={handleFilterReset()}
            >
              <FilterAltOffIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='sm' fixed>
      <Navigation
        paths={[
          {text: 'Acasa'       , path: '/'     , icon: 'Home'    },
          {text: 'Administrare', path: '/Admin', icon: 'Settings'},
          {text: 'Beneficiari' , path: null    , icon: 'Store'   },
        ]}
      />

      {renderFilter()}

      <Entities defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={{
          isMyCompany: 'desc',
          beneficiary: 'asc',
        }}
        renderEntity={{
          avatar: null,
          icon: null,
          textPrimary: entity => (
            <Highlighted highlight={filter.text} variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.beneficiary}</Highlighted>
          ),
          textSecondary: [
            entity => <Highlighted highlight={filter.text}>{entity.city}, {entity.address}, jud. {entity.county}</Highlighted>,
            entity => <Highlighted highlight={filter.text}>{entity.taxCode} / {entity.registerNumber}</Highlighted>,
            entity => <Highlighted highlight={filter.text}>{entity.phone} / {entity.email}</Highlighted>,
          ],
          textIcons: [
            entity => ({text: 'Aceasta este societatea mea'                 , icon: 'Home'         , color: 'primary' , when: entity.isMyCompany      }),
            entity => ({text: 'Acest beneficiar este activ'                 , icon: 'CheckCircle'  , color: 'primary' , when: entity.isEnabled        }),
            entity => ({text: 'Acest beneficiar nu este activ'              , icon: 'Cancel'       , color: 'error'   , when: !entity.isEnabled       }),
            entity => ({text: 'Acest beneficiar este o casa de asigurari'   , icon: 'LocalHospital', color: 'success' , when: entity.isInsuranceHouse }),
            entity => ({text: 'Acest beneficiar nu este o casa de asigurari', icon: 'LocalHospital', color: 'disabled', when: !entity.isInsuranceHouse}),
          ],
        }}
        contextualMenu={[
          entity => ({text: 'Modifica'        , icon: null, action: 'editEntity'                                             , when: true                                                        }),
          entity => ({text: 'Dezactiveaza'    , icon: null, action: 'disableEntity'                                          , when: !selection.entity.isMyCompany && selection.entity.isEnabled }),
          entity => ({text: 'Activeaza'       , icon: null, action: 'enableEntity'                                           , when: !selection.entity.isMyCompany && !selection.entity.isEnabled}),
          entity => ({text: 'Puncte de lucru' , icon: null, action: `/Admin/Branches/${selection.entity.id}`                 , when: true                                                        }),
          entity => ({text: 'Imprimante'      , icon: null, action: `/Admin/Printers/${selection.entity.id}`                 , when: selection.entity.isMyCompany                                }),
          entity => ({text: 'Contracte'       , icon: null, action: `/Admin/BeneficiaryContracts/${selection.entity.id}`     , when: !selection.entity.isMyCompany                               }),
          entity => ({text: 'Preturi servicii', icon: null, action: `/Admin/ServicePrices/MyCompany/${selection.entity.id}`  , when: selection.entity.isMyCompany                                }),
          entity => ({text: 'Preturi servicii', icon: null, action: `/Admin/ServicePrices/Beneficiary/${selection.entity.id}`, when: !selection.entity.isMyCompany                               }),
          entity => ({text: 'NIY - Module aplicatie', icon: null, action: `/Admin/AppModules/${selection.entity.id}`               , when: true                                                        }),
          entity => ({text: 'NIY - Utilizatori'     , icon: null, action: `/Admin/Users/${selection.entity.id}`                    , when: true                                                        }),
        ]}
        newEntity={{
          op: 'editEntity',
          entity: defs.beneficiary,
        }}
        onChangeSelection={setSelection}
      />

      {/**
       * Add/edit entity
       */}
      {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <Beneficiary
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as disabled
       */}
      {Boolean(selection.entity) && selection.op === 'disableEntity' &&
        <DisableBeneficiary
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as enabled
       */}
      {Boolean(selection.entity) && selection.op === 'enableEntity' &&
        <EnableBeneficiary
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Container>
  );
};

export default Beneficiaries;