import React, { useState, useContext, useEffect, useCallback } from 'react';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import Register from './Register';
import DisableRegister from './DisableRegister';
import EnableRegister from './EnableRegister';

const defaultFilter = {
  idBranch: '0',
  text: '',
};
const defaultDeps = {
  branches: [],
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Registers = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getRegisters')
      .then(response => {
        if (response.status.ok) {
          setData(response.result.data.registers);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Getters
   */
  const getBranches = useCallback(() => {
    return deps.branches.sort(firstBy('branch'));
  }, [deps]);

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = (response, newSelection = null) => {
    setData(response.result.data.registers);
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.registers.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} sm={6}>
        <TextField select
          disabled={ac.isLoading}
          label='Punct de lucru'
          placeholder='Punct de lucru'
          name='idBranch'
          value={filter.idBranch}
          onChange={handleFilterChange()}
        >
          <MenuItem value={defaultFilter.idBranch}><Typography variant='body1' fontStyle='italic' fontWeight='bold'>Toate</Typography></MenuItem>
          {getBranches().map(item => (
            <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.branch}</Typography></MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='sm' fixed>
      <Navigation
        paths={[
          {text: 'Acasa'       , path: '/'     , icon: 'Home'           },
          {text: 'Administrare', path: '/Admin', icon: 'Settings'       },
          {text: 'Registre'    , path: null    , icon: 'AppRegistration'},
        ]}
      />

      {renderFilter()}

      <Entities defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['branch', 'register']}
        groupBy={'branch'}
        renderEntity={{
          avatar: entity => <Avatar alt={entity.register}>{entity.register[0]}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Highlighted highlight={filter.text} variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.register}</Highlighted>
          ),
          textSecondary: [
            entity => <Highlighted highlight={filter.text}>Valabil pentru {entity.year ? `anul ${entity.year}` : 'toti anii'}</Highlighted>,
            entity => <Highlighted highlight={filter.text}>Ultimul document emis: {entity.serial} {entity.lastNumber}</Highlighted>,
          ],
          textIcons: [
            entity => ({text: 'Acest registru este activ'   , icon: 'CheckCircle', color: 'primary', when: entity.isEnabled }),
            entity => ({text: 'Acest registru nu este activ', icon: 'Cancel'     , color: 'error'  , when: !entity.isEnabled}),
          ],
        }}
        contextualMenu={[
          entity => ({text: 'Modifica'    , icon: null, action: 'editEntity'   , when: true                                                     }),
          entity => ({text: 'Dezactiveaza', icon: null, action: 'disableEntity', when: !selection.entity.isDefault && selection.entity.isEnabled}),
          entity => ({text: 'Activeaza'   , icon: null, action: 'enableEntity' , when: !selection.entity.isEnabled                              }),
        ]}
        newEntity={{
          op: 'editEntity',
          entity: defs.register,
        }}
        onChangeSelection={setSelection}
      />

      {/**
       * Add/edit entity
       */}
      {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <Register
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as disabled
       */}
      {Boolean(selection.entity) && selection.op === 'disableEntity' &&
        <DisableRegister
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as enabled
       */}
      {Boolean(selection.entity) && selection.op === 'enableEntity' &&
        <EnableRegister
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Container>
  );
};

export default Registers;